import {post} from "./index";

export function getBulkCrmCustomertwoList(data) {
    return post('/api/ajax/bulkCrmsCusList/getBulkCrmCustomertwoList.json', data)
}

export function getPrCyItemsInfo(data) {
    return post('/api/ajax/common/getPrCyItemsInfo.json', data)
}

export function getPayerHlxy(data) {
    return post('/api/ajax/bulkCrmsCusList/getPayerHlxy.json', data)
}

export function getPayerHlxy1(data) {
    return post('/api/ajax/bulkCrmsCusList/getPayerHlxy1.json', data)
}

export function getPayerHlpl(data) {
    return post('/api/ajax/bulkCrmsCusList/getPayerHlpl.json', data)
}

export function getPayerHlpl1(data) {
    return post('/api/ajax/bulkCrmsCusList/getPayerHlpl1.json', data)
}

export function getPayerHlxyOne(data) {
    return post('/api/ajax/bulkCrmsCusList/getPayerHlxyOne.json', data)
}

export function getPayerGroup(data) {
    return post('/api/ajax/bulkCrmsCusList/getPayerGroup.json', data)
}
